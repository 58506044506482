<template>
    <BalanceReport></BalanceReport>
  </template>
  
  <script>
  import BalanceReport from "@/components/ecologist/reports/BalanceReport/BalanceReport";
  export default {
    name: "BalanceReportView",
    components: { BalanceReport },
  };
  </script>
  
  <style scoped></style>
  